import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './assets/scss/main.scss';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';
import App from './App';

ReactDOM.render(
	<React.StrictMode>
		<I18nextProvider i18n={i18n}>
			<App />
		</I18nextProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
