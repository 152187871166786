import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import ProductFruits from 'react-product-fruits';
import { ToastContainer } from 'react-toastify';
import PrivateRoutes from './routes/private.routes';
import publicRoutes from './routes/public.routes';
import AppLayout from './components/AppLayout/AppLayout';
import 'react-toastify/dist/ReactToastify.css';
import AppSpinner from './components/LoaderSpinner';
import { getCache } from './services/storage';

const AppRoutes = () => {
	const userInfo = { username: getCache('userId') };
	const search = window.location.search;
	const isPDF = new URLSearchParams(search)?.get('pdfView');

	return (
		<>
			<ToastContainer />
			{!isPDF && (
				<ProductFruits
					projectCode={`${process.env.REACT_APP_PRODUCT_FRUIT_ID}`}
					language="en"
					{...userInfo}
				/>
			)}
			<Router>
				<Switch>
					{publicRoutes.map(({ component: Component, ...rest }, index) => (
						<PublicRoute key={index} component={Component} {...rest} />
					))}
					{PrivateRoutes.map((route, index) => (
						<PrivateRoute key={index} isVisible={route.visibleTo} {...route} />
					))}
				</Switch>
			</Router>
		</>
	);
};

const PrivateRoute = ({ component: Component, isVisible, ...rest }) => {
	const role = getCache('cur-role');
	const pdfView = rest?.location?.search.includes('pdfView=true');
	if (rest?.location?.search != '' && !role && !pdfView) {
		let path = `/login?pathname=${rest?.location?.pathname}${rest?.location?.search}`;
		return <Redirect to={path} />;
	}
	if (!role && !pdfView) {
		return <Redirect to="/login" />;
	}
	if (!isVisible?.includes(role) && !pdfView) {
		return <Redirect to="/pageError" />;
	}

	return (
		<AppLayout isLoggedIn>
			<Suspense fallback={<AppSpinner />}>
				<Route {...rest} component={Component} />
			</Suspense>
		</AppLayout>
	);
};
const PublicRoute = ({ component: Component, ...rest }) => {
	return (
		<Route {...rest}>
			<Suspense fallback={<AppSpinner />}>
				<Component />
			</Suspense>
		</Route>
	);
};
export default AppRoutes;
