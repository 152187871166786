import { combineReducers } from 'redux';
import lessonReducer from '../pages/OldLesson/lesson.store';
import loginReducer from '../pages/LoginPage/LoginPage.store';
import rubricsReducer from '../pages/Rubric/Rubric.store';
import curriculumReducer from '../pages/ContentLibrary/ContentLibrary.store';
import pubishSettingsReducer from '../components/PublishSettingModal/PublishSettings.store';
import courseReducer from '../pages/Courses/Courses.store';
import userReducer from '../pages/UserData/UserData.store';
import courseScheduleReducer from '../pages/CourseSchedules/CourseSchedules.store';
import groupReducer from '../pages/StudentGroups/StudentGroups.store';
import practicalAssessmentReducer from '../pages/PracticalAssesments/PracticalAssessments.store';
import studentReducer from '../pages/Student/Students.store';

const reducers = {
	loginStore: loginReducer,
	lessonStore: lessonReducer,
	rubricsStore: rubricsReducer,
	curriculumStore: curriculumReducer,
	courseStore: courseReducer,
	publishSettingsStore: pubishSettingsReducer,
	practicalAssessmentStore: practicalAssessmentReducer,
	userStore: userReducer,
	courseScheduleStore: courseScheduleReducer,
	groupStore: groupReducer,
	studentStore: studentReducer,
};

const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
	const appStates = { ...state };
	return appReducer(appStates, action);
};

export default rootReducer;
