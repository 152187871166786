import Axios from 'axios';
import { StorageKey } from '../helpers/constants';
import { getCache, setCache } from './storage';
import HandleError from './errorHandler';
import axios from 'axios';

const pending = new Map();
const API_URL = '';
const ACCESS_TOKEN_URL = 'accounts/refresh-token';

const axiosConfig = {
	baseURL: process.env.REACT_APP_API_URL || API_URL,

	headers: {
		'Content-Type': 'application/json',
	},
};

const addPending = config => {
	const url = [config.method, config.url].join('&');
	config.cancelToken =
		config.cancelToken ||
		new axios.CancelToken(cancel => {
			if (!pending.has(url) && !url.includes(ACCESS_TOKEN_URL)) {
				// If the current request does not exist in pending, add it
				pending.set(url, cancel);
			}
		});
};

const removePending = config => {
	const url = [config?.method, config?.url]?.join('&');
	const tempUrl = url?.slice(0, -1);
	const lastKeyInMap = [...pending?.keys()]?.pop();
	const urlKeywords = url?.split('keyword=');
	const lastKeyInMapKeywords = lastKeyInMap?.split('keyword=');
	const urlKeys = urlKeywords?.pop();
	const lastKeyInMapKeys = lastKeyInMapKeywords?.pop();

	if (pending.has(url)) {
		const cancel = pending.get(url);
		cancel(url);
		pending.delete(url);
	} else if (pending.has(tempUrl)) {
		// If the current request identity exists in pending, you need to cancel the current request and remove it
		const cancel = pending.get(tempUrl);
		cancel(tempUrl);
		pending.delete(tempUrl);
	} else if (
		lastKeyInMap &&
		lastKeyInMap.includes(url) &&
		lastKeyInMap !== url &&
		lastKeyInMap.includes('keyword=') &&
		urlKeywords?.length > 0 &&
		lastKeyInMapKeywords?.length > 0 &&
		lastKeyInMapKeys?.length > urlKeys?.length
	) {
		const cancel = pending.get(lastKeyInMap);
		cancel(lastKeyInMap);
		pending.delete(lastKeyInMap);
	}
};

export const clearPending = () => {
	for (const [url, cancel] of pending) {
		cancel(url);
	}
	pending.clear();
};

export const getToken = key => {
	const tmsUser = getCache(StorageKey.TMS_USER);
	let token = null;
	if (tmsUser && tmsUser.data.data[key]) {
		token = tmsUser.data.data[key];
	}
	return token;
};

function getApiConfig({ headers = {}, appConfig = {} }) {
	const mainConfig = {
		...axiosConfig,
		headers: {
			...axiosConfig.headers,
			...headers,
			Authorization: `Bearer ${getToken('token')}`,
		},
	};

	if (appConfig.baseURL === '') mainConfig.baseURL = appConfig.baseURL;
	if (appConfig.doNotNeedAuthorizationHeader) delete mainConfig.headers.Authorization;

	return mainConfig;
}

const ApiCall = ajaxParams => Axios(ajaxParams);

export const GET = ({ url = '', params = {}, headers = {} }) => {
	if (!url) throw new Error('Please specify a API URL');

	const config = getApiConfig({ headers });
	const ajaxParams = {
		...config,
		url,
		params,
		method: 'GET',
	};

	return ApiCall(ajaxParams);
};

export const POST = ({ url = '', params = {}, data = {}, headers = {}, appConfig = {} }) => {
	if (!url) throw new Error('Please specify a API URL');

	const config = getApiConfig({ headers, appConfig });

	const ajaxParams = {
		...config,
		url,
		data,
		params,
		method: 'POST',
	};

	return ApiCall(ajaxParams);
};

export const PATCH = ({ url = '', params = {}, data = {}, headers = {}, appConfig = {} }) => {
	if (!url) throw new Error('Please specify a API URL');

	const config = getApiConfig({ headers, appConfig });

	const ajaxParams = {
		...config,
		url,
		data,
		params,
		method: 'PATCH',
	};

	return ApiCall(ajaxParams);
};

export const FILE_POST = ({
	url = '',
	params = {},
	data = {},
	headers = {},
	appConfig = {},
	onUploadProgress = null,
}) => {
	if (!url) throw new Error('Please specify a API URL');

	const config = getApiConfig({ headers, appConfig });

	const ajaxParams = {
		...config,
		url,
		data,
		params,
		method: 'POST',
		onUploadProgress,
	};

	return Axios(ajaxParams);
};

export const PUT = ({ url = '', params = {}, data = {}, headers = {} }) => {
	if (!url) throw new Error('Please specify a API URL');

	const config = getApiConfig({ headers });

	const ajaxParams = {
		...config,
		url,
		data,
		params,
		method: 'PUT',
	};

	return ApiCall(ajaxParams);
};

export const DELETE = ({ url = '', params = {}, data = {}, headers = {} }) => {
	if (!url) throw new Error('Please specify a API URL');

	const config = getApiConfig({ headers });

	const ajaxParams = {
		...config,
		url,
		params,
		data,
		method: 'DELETE',
	};

	return ApiCall(ajaxParams);
};

function responseInterceptorFunction() {
	Axios.interceptors.response.use(
		function(response) {
			removePending(response);
			return response;
		},
		function(error) {
			try {
				const {
					config: originalRequest,
					response: { status = 0 },
				} = error;

				if (error?.config?.url.includes(ACCESS_TOKEN_URL) && status === 401) {
					localStorage.clear();
					window.location.href = '/login';
				}
				if (status === 412) {
					localStorage.clear();
					window.location.href = '/login';
				}
				if (!originalRequest?.url.includes('accounts/signin') && status === 401) {
					pending.clear();
					return POST({
						url: 'accounts/refresh-token',
						data: { refreshToken: getToken('refreshToken') },
					})
						.then(response => {
							const tmsUser = getCache(StorageKey.TMS_USER);
							tmsUser.data.data.token = response?.data?.data?.token;
							setCache(StorageKey.TMS_USER, tmsUser);
							originalRequest.headers.Authorization = `Bearer ${response?.data?.data?.token}`;
							return Axios(originalRequest);
						})
						.finally(responseInterceptorFunction);
				} else {
					if (status === 406 || status === 409 || status === 403) {
						HandleError(error?.response);
						return error?.response;
					}
					HandleError(error?.response);
					return Promise.reject(error?.response);
				}
			} catch {
				if (error?.message?.includes('Network Error')) {
					HandleError(error?.message);
					return Promise.reject(error?.message);
				}
			}
		}
	);
}
function requestInterceptorFunction() {
	axios.interceptors.request.use(
		config => {
			if (config?.url !== ACCESS_TOKEN_URL) {
				removePending(config); // Check previous requests to cancel before the request starts
				addPending(config); // Add current request to pending
			}
			return config;
		},
		error => {
			return Promise.reject(error);
		}
	);
}
requestInterceptorFunction();
responseInterceptorFunction();
