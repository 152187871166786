import React from 'react';
// import AppHeaderTab from './appHeader/AppHeaderTab';
// import sideBar from './sideBar';

const AppLayout = props => {
	const { children } = props;
	return (
		<>
			{/* <section className="app-l-main-container"> */}
			{/* <sideBar /> */}
			{/* <div className="app-l-base app-l-full-height">
					<div className="app-l-screen-header">
						<AppHeader />
					</div> */}
			{/* <div className="app-l-vertical-content-page"> */}
			{children}
			{/* </div> */}
			{/* </div>
			</section> */}
		</>
	);
};

export default AppLayout;
