import { GET, PATCH, DELETE, POST, PUT } from '../../services/http';
import { dateFormatterApi } from '../../helpers/utilities';

const FILE = 'FILE://GROUPS';

const generateActionType = type => `${FILE}/${type}`;

const GET_GROUPS_LIST = generateActionType('GET_GROUPS_LIST');
const SAVE_GROUP = generateActionType('SAVE_GROUP');
const GET_GROUP_DETAILS_DATA = generateActionType('GET_GROUP_DETAILS_DATA');

export const getGroups = data => ({
	type: GET_GROUPS_LIST,
	data,
});

export const archiveGroups = async (id, isActive) => {
	const req = {
		url: `/groups/${id}`,
		data: { active: !isActive },
	};
	const response = await PATCH(req);
	return response;
};

export const activateGroupsDetails = async (id, status) => {
	const req = {
		url: `/groups/${id}`,
		data: { active: status },
	};

	const response = await PATCH(req);
	return response;
};

export const deleteGroups = async id => {
	const req = {
		url: `groups/${id}`,
	};
	const response = await DELETE(req);
	if (response.status === 204) {
		return response;
	}
};

export const listGroups = async (
	page,
	pageSize,
	sortKey,
	sortOrderAsc,
	keyword,
	searchKey,
	statusFilterKey,
	statusFilterCol,
	startDate,
	endDate
) => {
	const sortOrder = sortOrderAsc ? 'asc' : 'desc';
	let req = {};
	let url = `groups?limit=${pageSize}&page=${page}&sortKey=${sortKey}&sortOrder=${sortOrder}`;
	if (searchKey && keyword?.trim().length) {
		url += `&searchKey=${searchKey}`;
	}
	if (keyword?.trim().length) {
		let key;
		if (keyword.includes('#') || keyword.includes('&')) {
			key = encodeURIComponent(keyword);
		} else {
			key = keyword
				.trim()
				.toLowerCase()
				.replace(/[^a-z\d#&]/g, '\\$&');
		}
		// let key = keyword
		// 	.toLowerCase()
		// 	.trim()
		// 	.replace(/[^a-z\d#&]/g, '\\$&');
		url += `&keyword=${key?.trim()}`;
	}
	if (statusFilterCol?.trim().length && statusFilterKey?.length > 0) {
		url += `&${'status'}=${statusFilterKey?.map(x => x.value).join(',')}`;
	}
	if (startDate && endDate) {
		url += `&createdFrom=${dateFormatterApi(startDate)}&createdTo=${dateFormatterApi(endDate)}`;
	}
	req = {
		url,
	};

	const response = await GET(req);
	if (response?.status === 200) {
		return response;
	}
};
export const getGroupDetailsData = data => ({
	type: GET_GROUP_DETAILS_DATA,
	data,
});

export const saveGroup = data => ({
	type: SAVE_GROUP,
	data,
});

export const getGroupDetails = async id => {
	const req = {
		url: `groups/${id}`,
	};

	const response = await GET(req);
	return response;
};

export const saveGroupDetails = async groups => {
	const req = {
		url: '/groups',
		data: groups,
	};
	if (groups._id) {
		req.url = `/groups/${groups._id}`;
		const response = await PUT(req);
		return response;
	}

	const response = await POST(req);

	return response;
};

export const activateGroupDetails = async (id, status) => {
	const req = {
		url: `/groups/${id}`,
		data: { active: status },
	};

	const response = await PATCH(req);
	return response;
};

export const publishGroups = async id => {
	const req = {
		url: `/groups/${id}`,
		data: { publish: true },
	};

	const response = await PATCH(req);
	return response;
};

export const listOrganization = async () => {
	let req = {};
	let url = `organizations`;
	req = {
		url,
	};

	const response = await GET(req);
	if (response.status === 200) {
		return response;
	}
};

function reducer(state, action) {
	switch (action.type) {
		case GET_GROUPS_LIST:
			return {
				...state,
				...action.data,
			};
		case SAVE_GROUP:
			return {
				...state,
				groupData: action.data,
			};

		default:
			return null;
	}
}

export default reducer;
