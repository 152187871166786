import { StorageKey } from '../../helpers/constants';
import { DELETE, GET, PATCH, POST, PUT } from '../../services/http';
import { getCache, remCache, setCache } from '../../services/storage';

const FILE = 'FILE://RUBRICS';

const generateActionType = type => `${FILE}/${type}`;

const GET_RUBRICS_DATA = generateActionType('GET_RUBRICS_DATA');
const GET_TAGS_LIST = generateActionType('GET_TAGS_LIST');
const GET_RUBRICS_DETAILS_DATA = generateActionType('GET_RUBRICS_DETAILS_DATA');
const SAVE_RUBRICS = generateActionType('SAVE_RUBRICS');
const DELETE_RUBRICS = generateActionType('DELETE_RUBRICS');
const GET_VERSION_HISTORY = generateActionType('GET_VERSION_HISTORY');
const VERSION_HISTORY_DETAILS = generateActionType('VERSION_HISTORY_DETAILS');
const CLEAR_RUBRICS = generateActionType('CLEAR_RUBRICS');

export const clearRubrics = data => ({
	type: CLEAR_RUBRICS,
	data,
});

export const getRubricsData = data => ({
	type: GET_RUBRICS_DATA,
	data,
});
export const getRubricsDetailsData = data => ({
	type: GET_RUBRICS_DETAILS_DATA,
	data,
});
export const getTagsList = data => ({
	type: GET_TAGS_LIST,
	data,
});
export const getVersionList = data => ({
	type: GET_VERSION_HISTORY,
	data,
});
export const getVersionHistoryDetails = data => ({
	type: VERSION_HISTORY_DETAILS,
	data,
});

export const deleteRubricsData = async deleted => {
	const req = {
		url: `rubrics/${deleted}`,
		data: { language: 'en' },
	};
	const response = await DELETE(req);
	if (response.status === 204) {
		return response;
	}
};
export const archiveRubricsData = async (archived, isActive) => {
	const req = {
		url: `rubrics/${archived}`,
		data: {
			active: !isActive,
			language: 'en',
		},
	};
	const response = await PATCH(req);
	if (response.status === 204) {
		return response;
	}
};

export const createRubrics = async rubrics => {
	const req = {
		url: '/rubrics',
		data: rubrics,
	};

	const response = await POST(req);
	return response;
};

export const updateRubricTags = async (model, id) => {
	const req = {
		url: `/rubrics/${id}`,
		data: model,
	};
	const response = await PATCH(req);
	return response;
};

export const publishRubrics = async (rubricsId, data) => {
	const req = {
		url: `/rubrics/${rubricsId}`,
		data,
	};

	const response = await PATCH(req);
	return response;
};

export const deleteRubricsDetails = async rubricsId => {
	const req = {
		url: `/rubrics/${rubricsId}`,
		data: { language: 'en' },
	};

	const response = await DELETE(req);
	return response;
};

export const activateRubricsDetails = async (rubricsId, status) => {
	const req = {
		url: `/rubrics/${rubricsId}`,
		data: { active: status, language: 'en' },
	};

	const response = await PATCH(req);
	return response;
};

export const saveRubrics = data => ({
	type: SAVE_RUBRICS,
	data,
});

export const saveRubricDetails = async (rubrics, isSaveToPool, isUpdateRubricPool) => {
	const req = {
		url: '/rubrics',
		data: rubrics,
	};
	let response;
	if (isSaveToPool) {
		if (isUpdateRubricPool) {
			req.url = `/rubrics/${rubrics.refId}`;
			response = await PUT(req);
			return response;
		} else {
			response = await POST(req);
		}
	} else {
		if (rubrics._id) {
			req.url = `/rubrics/${rubrics._id}`;
			response = await PUT(req);
			return response;
		} else {
			response = await POST(req);
		}
	}
	return response;
};

export const deleteRubrics = () => ({
	type: DELETE_RUBRICS,
});

export const listRubricsData = async (
	page,
	pageSize,
	sortKey,
	sortOrderAsc,
	keyword,
	searchKey,
	startDate,
	endDate,
	selected
) => {
	const sortOrder = sortOrderAsc ? 'asc' : 'desc';
	let req = {};
	let url = `rubrics?limit=${pageSize}&page=${page}&sortKey=${sortKey}&sortOrder=${sortOrder}`;
	if (keyword?.trim().length && searchKey) {
		url += `&searchKey=${searchKey}&keyword=${keyword?.trim()}`;
	}
	if (selected?.length > 0) {
		url += `&status=${selected.map(x => x.value).join(',')}`;
	}
	if (startDate && endDate) {
		url += `&publishedFrom=${startDate}&publishedTo=${endDate}`;
	}
	req = {
		url,
	};

	const response = await GET(req);
	if (response.status === 200) {
		return response;
	}
};
export const rubricsDetailsData = async (id, dispatch) => {
	const req = {
		url: `rubrics/${id}`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		dispatch(getRubricsDetailsData(response));
		dispatch(saveRubrics(response.data?.data));
		return response;
	}
};
export const tagsList = async dispatch => {
	const req = {
		url: `/tags`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		dispatch(getTagsList(response));
		return response;
	}
};
export const authorsList = async dispatch => {
	const req = {
		url: `/authors`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		dispatch(getTagsList(response));
		return response;
	}
};
export const versionHistory = async (id, dispatch) => {
	const req = {
		url: `/rubrics/${id}/revisions`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		dispatch(getVersionList(response));
		return response;
	}
};
export const versionHistoryDetails = async (id, revisionId, dispatch) => {
	const req = {
		url: `/rubrics/${id}/revisions/${revisionId}`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		dispatch(getVersionHistoryDetails(response));
		return response;
	}
};

function reducer(state, action) {
	const initialData = getCache(StorageKey.RUBRICS_DATA);
	switch (action.type) {
		case SAVE_RUBRICS:
			setCache(StorageKey.RUBRICS_DATA, { rubric: { ...state?.rubric, ...action.data } });
			return {
				...state,
				rubric: { ...state?.rubric, ...action.data },
			};
		case CLEAR_RUBRICS:
			setCache(StorageKey.RUBRICS_DATA, { rubric: { ...action.data } });
			return {
				...state,
				rubric: { ...action.data },
			};
		case GET_RUBRICS_DATA:
			return {
				...state,
				...action.data,
			};
		case GET_RUBRICS_DETAILS_DATA:
			return {
				...state,
				rubricsDetails: action.data.data.data,
			};
		case GET_TAGS_LIST:
			return {
				...state,
				tags: action.data.data.data.tags,
			};
		case DELETE_RUBRICS:
			remCache(StorageKey.RUBRICS_DATA);
			return null;
		case GET_VERSION_HISTORY:
			// setCache(StorageKey.GET_VERSION_HISTORY, action.data);
			return {
				...state,
				versionHistory: action.data.data.data.records,
			};
		case VERSION_HISTORY_DETAILS:
			return {
				...state,
				versionHistoryDetails: action.data,
			};
		default:
			return initialData;
	}
}
export default reducer;
