import dayjs from 'dayjs';
import dayjsPluginUTC from 'dayjs-plugin-utc';
import { useCallback, useEffect, useRef, useState } from 'react';

dayjs.extend(dayjsPluginUTC);

export function dateFormatterParseInt(date) {
	return dayjs.utc(parseInt(date)).format('DD MMM YYYY');
}

export function dateFormatter(date) {
	return dayjs.utc(date).format('DD MMM YYYY');
}

export function dateFormatterNonUtc(date) {
	return dayjs(date).format('DD MMM YYYY');
}
export function timeFormatter(date) {
	return dayjs.utc(parseInt(date)).format('HH:mm A');
}

export function getMonth(date) {
	return dayjs(date, 'DD MMM YYYY').format('M');
}

export function getYear(date) {
	return dayjs(date, 'DD MMM YYYY').format('YYYY');
}

export function dateFormatterApi(date) {
	return dayjs(date).format('YYYY-MM-DD');
}

export function DateFormatter(date) {
	return dayjs(date).format('DD MMM YYYY');
}

export function timeFormatterApi(time) {
	return dayjs.utc(time).format('HH:mm:ss');
}

export function dateTimeFormatterApi(dateTime) {
	return dayjs.utc(dateTime).format('YYYY-MM-DD[T]HH:mm:ss[Z]');
}

export function startDateFormatterApi(dateTime) {
	return dayjs
		.utc(dateTime)
		.startOf('date')
		.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
}
export function endDateFormatterApi(dateTime) {
	return dayjs
		.utc(dateTime)
		.endOf('date')
		.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
}
export function endDateFormatter(dateTime) {
	return dayjs
		.utc(dateTime)
		.endOf('date')
		.format('DD MMM YYYY');
}
export function getCurrentDateTime() {
	return dayjs();
}

export function getDateTimeDiff(dateTime) {
	const currentDate = dayjs();
	const newDate = dayjs(dateTime);
	const dateTimeDiff = currentDate.diff(newDate, 'second');
	return dateTimeDiff;
}

export function dateTimeFormatter(dateTime) {
	return dayjs(dateTime)
		.local()
		.format(`D MMM hh:mm a`);
}
export function ApiTimeFormatter(dateTime) {
	return dayjs(dateTime)
		.local()
		.format(`hh:mm A`);
}
export function isNetworkConnected() {
	let isConnected = true;
	const condition = navigator.onLine ? 'online' : 'offline';

	if (condition === 'online') {
		const webPing = setInterval(() => {
			fetch('//google.com', {
				mode: 'no-cors',
			})
				.then(() => {
					clearInterval(webPing);
					isConnected = true;
				})
				.catch(() => {
					isConnected = false;
				});
		}, 2000);
		return isConnected;
	} else {
		isConnected = false;
	}
	return isConnected;
}

let globalIndex = 0;

export const lazyRun = function(fn, timeout) {
	timeout = timeout ? timeout : 500;
	globalIndex++;
	let localIndex = globalIndex;
	setTimeout(() => {
		if (localIndex === globalIndex && fn) {
			fn();
		}
	}, timeout);
};

export function isMobileCheck() {
	// const handleResize = () => {
	if (window.innerWidth < 720) {
		return true;
	} else {
		return false;
	}
	// };
	// useEffect(() => {
	// 	handleResize();
	// 	window.addEventListener('resize', handleResize);
	// }, []);
}

export function useStateCallback(initialState) {
	const [state, setState] = useState(initialState);
	const cbRef = useRef();
	const setStateCallback = useCallback((state, cb) => {
		if (cb) cbRef.current = cb;
		setState(state);
	}, []);
	useEffect(() => {
		if (cbRef.current) {
			cbRef.current(state);
			cbRef.current = null;
		}
	}, [state]);
	return [state, setStateCallback];
}

export function searchFilterOptions(options, filter) {
	if (!filter) {
		return options;
	}
	const re = new RegExp(filter, 'i');
	return options.filter(({ value }) => value && value.match(re));
}
export const toNum = e => {
	const value = e.target.value;
	const num = value.replace(/[^0-9.]/g, '');
	return num;
};
export const createImage = url =>
	new Promise((resolve, reject) => {
		const image = new Image();
		image.addEventListener('load', () => resolve(image));
		image.addEventListener('error', error => reject(error));
		image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
		image.src = url;
	});

export const removeEditorBar = () => {
	document.body.classList.remove('hide-editor-toolbar');
};
export const addEditorBar = () => {
	document.body.classList.add('hide-editor-toolbar');
};
export const isValidateImageFormat = type => {
	const imageFileTypes = [
		'image/jpeg',
		'image/jpg',
		'image/png',
		'image/webp',
		'image/gif',
		'jpg',
		'jpeg',
		'png',
		'webp',
		'gif',
	];
	if (imageFileTypes.includes(type?.toLowerCase())) return true;
};

export const isValidateDocFormat = type => {
	const docFileTypes = ['pdf', 'xlsx', 'ppt', 'image/webp', 'pptx', 'doc', 'docx'];
	if (docFileTypes.includes(type?.toLowerCase())) return true;
};

export const isValidateVideoFormat = type => {
	const videoFileTypes = ['mp4'];
	if (videoFileTypes.includes(type?.toLowerCase())) return true;
};

export const isValidateAudioFormat = type => {
	const audioFileTypes = ['mp3'];
	if (audioFileTypes.includes(type?.toLowerCase())) return true;
};

export default function useOutsideAlerter(ref, fun) {
	useEffect(() => {
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				fun();
			}
		}
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);
}
export const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

export const getItemStyle = (isDragging, draggableStyle) => ({
	// change background colour if dragging
	background: isDragging ? '#F7F6F2' : 'transparent',

	// styles we need to apply on draggables
	...draggableStyle,
});

export const getListStyle = isDraggingOver => ({
	border: isDraggingOver ? '1px dashed var(--theme-primary-color)' : 'transparent',
});
