import { StorageKey } from '../../helpers/constants';
import { DELETE, GET, PATCH, POST, PUT } from '../../services/http';
import { getCache, remCache, setCache } from '../../services/storage';
import { dateFormatterApi } from '../../helpers/utilities';

const FILE = 'FILE://COURSE';

const generateActionType = type => `${FILE}/${type}`;

const SAVE_COURSE = generateActionType('SAVE_COURSE');
const DELETE_COURSE = generateActionType('DELETE_COURSE');
const COURSE_DETAILS = generateActionType('COURSE_DETAILS');
const SAVE_COURSE_LIST = generateActionType('SAVE_COURSE_LIST');
const STORE_COURSE_DETAILS_DATA = generateActionType('STORE_COURSE_DETAILS_DATA');
const SKILLS_LIST = generateActionType('SKILLS_LIST');
const ACTIVATE_COURSE_ITEMS = generateActionType('ACTIVATE_COURSE_ITEMS');
const CLEAR_COURSE = generateActionType('CLEAR_COURSE');

export const saveCourse = data => ({
	type: SAVE_COURSE,
	data,
});

export const clearCourse = data => ({ type: CLEAR_COURSE, data });

export const getSkillsList = data => ({
	type: SKILLS_LIST,
	data,
});

export const deleteCourse = () => ({
	type: DELETE_COURSE,
});

export const getCourseDetailsData = data => ({
	type: COURSE_DETAILS,
	data,
});
export const storeCourseDetailsData = data => ({
	type: STORE_COURSE_DETAILS_DATA,
	data,
});

export const saveCourseList = data => ({
	type: SAVE_COURSE_LIST,
	data,
});

export const activateCourseItems = data => ({
	type: ACTIVATE_COURSE_ITEMS,
	data,
});

export const listCourseData = async (
	page,
	pageSize,
	sortKey,
	sortOrderAsc,
	keyword,
	startDate,
	endDate
) => {
	const sortOrder = sortOrderAsc ? 'asc' : 'desc';
	let req = {};
	let url = `courses?limit=${pageSize}&page=${page}&sortKey=${sortKey}&sortOrder=${sortOrder}`;
	if (keyword?.trim().length) {
		let key;
		if (keyword.includes('#') || keyword.includes('&')) {
			key = encodeURIComponent(keyword);
		} else {
			key = keyword
				.trim()
				.toLowerCase()
				.replace(/[^a-z\d#&]/g, '\\$&');
		}
		// let key = keyword
		// 	.toLowerCase()
		// 	.trim()
		// 	.replace(/[^a-z\d#&]/g, '\\$&');
		url += `&keyword=${key?.trim()}`;
	}
	if (startDate && endDate) {
		url += `&publishedFrom=${dateFormatterApi(startDate)}&publishedTo=${dateFormatterApi(endDate)}`;
	}
	req = {
		url,
	};

	const response = await GET(req);
	if (response?.status === 200) {
		return response;
	}
};

export const deleteCourseData = async id => {
	const req = {
		url: `courses/${id}`,
		data: { language: 'en' },
	};
	const response = await DELETE(req);
	if (response?.status === 204) {
		return response;
	}
};

export const archiveCourseData = async (id, isActive) => {
	const req = {
		url: `courses/${id}`,
		data: {
			active: !isActive,
			language: 'en',
		},
	};
	const response = await PATCH(req);
	if (response?.status === 204 || response?.status === 200 || response?.status === 406) {
		return response;
	}
};
export const courseDetailsData = async (id, dispatch) => {
	const req = {
		url: `courses/${id}`,
	};

	const response = await GET(req);
	if (response?.status === 200) {
		dispatch(storeCourseDetailsData(response));
		return response;
	}
};

export const getCourseDetails = async (id, dispatch) => {
	const req = {
		url: `courses/${id}`,
	};

	const response = await GET(req);
	if (response?.status === 200) {
		dispatch(saveCourse(response.data.data));
		return response;
	}
};
export const getTopics = async () => {
	const req = {
		url: `topics`,
	};

	const response = await GET(req);
	if (response?.status === 200) {
		return response;
	}
};

export const addTopics = async topic => {
	const req = {
		url: `topics`,
		data: { title: topic },
	};

	const response = await POST(req);
	if (response?.status === 201 || response?.status === 406) {
		return response;
	}
};

export const saveCourseDetails = async course => {
	const req = {
		url: '/courses',
		data: course,
	};
	if (course._id) {
		req.url = `/courses/${course._id}`;
		const response = await PUT(req);
		return response;
	}

	const response = await POST(req);
	return response;
};

export const publishCourse = async (courseId, data) => {
	const req = {
		url: `/courses/${courseId}`,
		data,
	};

	const response = await PATCH(req);
	return response;
};
export const updateCourseTags = async (model, id) => {
	const req = {
		url: `/courses/${id}`,
		data: model,
	};
	const response = await PATCH(req);
	return response;
};

export const skillsList = async dispatch => {
	const req = {
		url: `/skills`,
	};
	const response = await GET(req);
	if (response?.status === 200) {
		dispatch(getSkillsList(response));
		return response;
	}
};

function reducer(state, action) {
	const initialData = getCache(StorageKey.COURSE_DATA);
	switch (action.type) {
		case SAVE_COURSE:
			setCache(StorageKey.COURSE_DATA, { course: { ...state?.course, ...action.data } });
			return {
				...state,
				course: { ...state.course, ...action.data },
			};
		case CLEAR_COURSE:
			setCache(StorageKey.COURSE_DATA, { course: { ...action.data } });
			return {
				...state,
				course: { ...action.data },
			};
		case DELETE_COURSE:
			remCache(StorageKey.COURSE_DATA);
			return null;
		case SAVE_COURSE_LIST:
			return {
				...state,
				...action.data.data,
			};

		case STORE_COURSE_DETAILS_DATA:
			return {
				...state,
				...action.data.data,
			};
		case SKILLS_LIST:
			return {
				...state,
				skills_list: { ...action.data.data.data },
			};
		default:
			return initialData;
	}
}
export default reducer;
