import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { AppStore } from './store';
import AppRoutes from './Routes';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import NoNetworkImage from './assets/images/no-network.svg';

function App() {
	const { t } = useTranslation();
	let imageUrl = new Image();
	imageUrl.src = NoNetworkImage;
	let [online, isOnline] = useState(navigator.onLine);

	const setOnline = () => {
		isOnline(true);
	};

	const setOffline = () => {
		isOnline(false);
	};

	useEffect(() => {
		window.addEventListener('offline', setOffline);
		window.addEventListener('online', setOnline);
		return () => {
			window.removeEventListener('offline', setOffline);
			window.removeEventListener('online', setOnline);
		};
	}, []);

	return (
		<>
			{process.env.REACT_APP_API_URL !== 'https://qualifytms-api.experionglobal.dev/' ? (
				<>
					{!online && (
						<div className="no-network">
							<img src={imageUrl.src} className="no-network--image" alt="no network image" />

							<div className="no-network--title">{t('label.noInternet')}</div>
							<div className="no-network--explaination">
								Please check your network connection and try again.
							</div>
						</div>
					)}

					{online && (
						<>
							<img
								src={imageUrl.src}
								className="no-network--image"
								alt="no network image"
								style={{ display: 'none' }}
							/>
							<Provider store={AppStore}>
								<AppRoutes />
							</Provider>
						</>
					)}
				</>
			) : (
				<Provider store={AppStore}>
					<AppRoutes />
				</Provider>
			)}
		</>
	);
}

export default App;
