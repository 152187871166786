import { StorageKey } from '../../helpers/constants';
import { GET } from '../../services/http';
import { setCache, getCache } from '../../services/storage';

const FILE = 'FILE://PUBLISH_SETTINGS';

const generateActionType = type => `${FILE}/${type}`;

const GET_AUTHORS = generateActionType('GET_AUTHORS');
const GET_TAGS = generateActionType('GET_TAGS');
const GET_RECIPIENTS = generateActionType('GET_RECIPIENTS');

export const getTagsList = data => ({
	type: GET_TAGS,
	data,
});

export const getAuthorsList = data => ({
	type: GET_AUTHORS,
	data,
});

export const getRecipientsList = data => ({
	type: GET_RECIPIENTS,
	data,
});

export const tagsList = async dispatch => {
	const req = {
		url: `/tags`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		dispatch(getTagsList(response));
		return response;
	}
};
export const authorsList = async dispatch => {
	const req = {
		url: `/authors`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		dispatch(getAuthorsList(response));
		return response;
	}
};
export const recipientsList = async dispatch => {
	const req = {
		url: `/users?type=dropdown&role=qa`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		dispatch(getRecipientsList(response));
		return response;
	}
};

function reducer(state, action) {
	const initialData = getCache(StorageKey.GET_TAGS);
	switch (action.type) {
		case GET_TAGS:
			setCache(StorageKey.GET_TAGS, action.data);
			return {
				...state,
				tags: action.data.data.data,
			};
		case GET_AUTHORS:
			setCache(StorageKey.GET_AUTHORS, action.data);
			return {
				...state,
				authors: action.data.data.data,
			};
		case GET_RECIPIENTS:
			setCache(StorageKey.GET_RECIPIENTS, action.data);
			return {
				...state,
				recipients: action.data.data,
			};
		default:
			return initialData;
	}
}

export default reducer;
