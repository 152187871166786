import { StorageKey } from '../../helpers/constants';
import { GET, PUT, POST, PATCH, DELETE } from '../../services/http';
import { setCache, getCache } from '../../services/storage';

const FILE = 'FILE://PRACTICAL_ASSESSMENTS';

const generateActionType = type => `${FILE}/${type}`;

const GET_ASSESSMENT_DATA = generateActionType('GET_ASSESSMENT_DATA');
const GET_TAGS_LIST = generateActionType('GET_TAGS_LIST');
const GET_PA_DETAILS = generateActionType('GET_PA_DETAILS');
const SAVE_PA = generateActionType('SAVE_PA');
const CLEAR_PA = generateActionType('CLEAR_PA');
const GET_ASSESSMENT_DETAILS_DATA = generateActionType('GET_ASSESSMENT_DETAILS_DATA');
const GET_ASSESSMENT_HISTORY = generateActionType('GET_ASSESSMENT_HISTORY');
const GET_ASSESSMENT_HISTORY_DETAILS = generateActionType('GET_ASSESSMENT_HISTORY_DETAILS');

export const clearPA = data => ({
	type: CLEAR_PA,
	data,
});

export const getAssessmentDetailsData = data => ({
	type: GET_ASSESSMENT_DETAILS_DATA,
	data,
});

export const getTagsList = data => ({
	type: GET_TAGS_LIST,
	data,
});

export const getPADetailsData = data => ({
	type: GET_PA_DETAILS,
	data,
});

export const savePA = data => ({
	type: SAVE_PA,
	data,
});

export const getAssessmentData = data => ({
	type: GET_ASSESSMENT_DATA,
	data,
});

export const getAssessmentHistory = data => ({
	type: GET_ASSESSMENT_HISTORY,
	data,
});

export const getAssessmentHistoryDetails = data => ({
	type: GET_ASSESSMENT_HISTORY_DETAILS,
	data,
});
export const getPADetails = async (id, dispatch) => {
	const req = {
		url: `practicalAssessments/${id}`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		dispatch(getPADetailsData(response));
		dispatch(clearPA({ ...response?.data?.data }));

		return response;
	}
};

export const getTaskList = async (keyword, searchKey) => {
	let req = {};
	let url = `taskPools?type=dropdown`;
	if (searchKey && keyword?.trim().length) {
		url += `&searchKey=${searchKey}`;
	}
	if (keyword?.trim().length) {
		let key;
		if (keyword.includes('#') || keyword.includes('&')) {
			key = encodeURIComponent(keyword);
		} else {
			key = keyword
				.trim()
				.toLowerCase()
				.replace(/[^a-z\d#&]/g, '\\$&');
		}
		// let key = keyword
		// 	.toLowerCase()
		// 	.trim()
		// 	.replace(/[^a-z\d#&]/g, '\\$&');
		url += `&keyword=${key?.trim()}`;
	}

	req = {
		url,
	};

	const response = await GET(req);
	if (response?.status === 200) {
		return response;
	}
};

export const tagsList = async dispatch => {
	const req = {
		url: `/tags`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		dispatch(getTagsList(response));
		return response;
	}
};

export const createPracticalAssessment = async data => {
	let response;
	if (data._id) {
		const req = {
			url: `/practicalAssessments/${data._id}`,
			data,
		};
		response = await PUT(req);
	} else {
		const req = {
			url: `/practicalAssessments`,
			data,
		};
		response = await POST(req);
	}
	return response;
};
export const saveTask = async (data, isUpdate) => {
	let response;
	const req = {
		url: `/taskPools`,
		data: { ...data, language: 'en' },
	};
	if (isUpdate) {
		req.url = `/taskPools/${data.refId}`;
		response = await PUT(req);
		return response;
	}
	response = await POST(req);
	return response;
};

export const publishAssessment = async (id, data) => {
	const req = {
		url: `/practicalAssessments/${id}`,
		data,
	};
	const response = await PATCH(req);
	return response;
};

export const deleteTask = async id => {
	const req = {
		url: `/taskPools/${id}`,
		data: { language: 'en' },
	};
	const response = await DELETE(req);
	return response;
};

export const updateAssessmentTags = async (model, id) => {
	const req = {
		url: `/practicalAssessments/${id}`,
		data: model,
	};
	const response = await PATCH(req);
	return response;
};

export const listAssessmentData = async (
	page,
	pageSize,
	sortKey,
	sortOrderAsc,
	keyword,
	searchKey,
	startDate,
	endDate,
	selected
) => {
	const sortOrder = sortOrderAsc ? 'asc' : 'desc';
	let req = {};
	let url = `practicalAssessments?limit=${pageSize}&page=${page}&sortKey=${sortKey}&sortOrder=${sortOrder}`;
	if (keyword?.trim().length && searchKey) {
		let key;
		if (keyword.includes('#') || keyword.includes('&')) {
			key = encodeURIComponent(keyword);
		} else {
			key = keyword
				.trim()
				.toLowerCase()
				.replace(/[^a-z\d#&]/g, '\\$&');
		}
		url += `&searchKey=${searchKey}&keyword=${key?.trim()}`;
	}
	if (selected?.length > 0) {
		url += `&status=${selected.map(x => x.value).join(',')}`;
	}
	if (startDate && endDate) {
		url += `&publishedFrom=${startDate}&publishedTo=${endDate}`;
	}
	req = {
		url,
	};

	const response = await GET(req);
	if (response.status === 200) {
		return response;
	}
};

export const activateAssessmentDetails = async (assessmentId, status) => {
	const req = {
		url: `/practicalAssessments/${assessmentId}`,
		data: { active: !status, language: 'en' },
	};

	const response = await PATCH(req);
	return response;
};

export const deleteAssessmentDetails = async assessmentId => {
	const req = {
		url: `/practicalAssessments/${assessmentId}`,
		data: { language: 'en' },
	};

	const response = await DELETE(req);
	return response;
};

export const deleteAssessmentData = async deleted => {
	const req = {
		url: `practicalAssessments/${deleted}`,
		data: { language: 'en' },
	};
	const response = await DELETE(req);
	if (response.status === 204) {
		return response;
	}
};

export const assessmentDetailsData = async (id, dispatch) => {
	const req = {
		url: `practicalAssessments/${id}`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		dispatch(getAssessmentDetailsData(response));
		return response;
	}
};

export const assessmentVersionHistory = async (id, dispatch) => {
	const req = {
		url: `practicalAssessments/${id}/revisions`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		dispatch(getAssessmentHistory(response));
		return response;
	}
};

export const assessmentHistoryDetails = async (id, revisionId, dispatch) => {
	const req = {
		url: `practicalAssessments/${id}/revisions/${revisionId}`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		dispatch(getAssessmentHistoryDetails(response));
		return response;
	}
};

function reducer(state, action) {
	const initialData = getCache(StorageKey.PRACTICAL_ASSESSMENT);
	switch (action.type) {
		case SAVE_PA:
			setCache(StorageKey.PRACTICAL_ASSESSMENT, { pa: { ...state?.pa, ...action.data } });
			return {
				...state,
				pa: { ...state.pa, ...action.data },
			};
		case CLEAR_PA:
			setCache(StorageKey.PRACTICAL_ASSESSMENT, { pa: { ...action.data } });
			return {
				...state,
				pa: { ...action.data },
			};
		case GET_PA_DETAILS:
			return {
				...state,
				...action.data,
			};
		default:
			return initialData;
	}
}
export default reducer;
