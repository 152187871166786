import { StorageKey } from '../../helpers/constants';
import { DELETE, GET, PATCH, POST, PUT } from '../../services/http';
import { getCache, remCache, setCache } from '../../services/storage';

const FILE = 'FILE://LESSON';

const generateActionType = type => `${FILE}/${type}`;

const GET_LESSON_DATA = generateActionType('GET_LESSON_DATA');
const GET_TAGS_LIST = generateActionType('GET_TAGS_LIST');
const GET_LESSON_DETAILS_DATA = generateActionType('GET_LESSON_DETAILS_DATA');
const SAVE_LESSON = generateActionType('SAVE_LESSON');
const DELETE_LESSON = generateActionType('DELETE_LESSON');
const GET_LESSON_HISTORY = generateActionType('GET_LESSON_HISTORY');
const GET_LESSON_HISTORY_DETAILS = generateActionType('GET_LESSON_HISTORY_DETAILS');
const SAVE_FILE_SIZE = generateActionType('SAVE_FILE_SIZE');
const SAVE_SCREENS_NUM = generateActionType('SAVE_SCREENS_NUM');
const CLEAR_LESSON = generateActionType('CLEAR_LESSON');

export const clearLesson = data => ({
	type: CLEAR_LESSON,
	data,
});

export const getLessonData = data => ({
	type: GET_LESSON_DATA,
	data,
});
export const getLessonDetailsData = data => ({
	type: GET_LESSON_DETAILS_DATA,
	data,
});
export const getTagsList = data => ({
	type: GET_TAGS_LIST,
	data,
});
export const getLessonHistory = data => ({
	type: GET_LESSON_HISTORY,
	data,
});
export const getLessonHistoryDetails = data => ({
	type: GET_LESSON_HISTORY_DETAILS,
	data,
});

export const deleteLessonData = async deleted => {
	const req = {
		url: `lessons/${deleted}`,
		data: { language: 'en' },
	};
	const response = await DELETE(req);
	if (response.status === 204) {
		return response;
	}
};
export const archiveLessonData = async (archived, isActive) => {
	const req = {
		url: `lessons/${archived}`,
		data: {
			active: !isActive,
			language: 'en',
		},
	};
	const response = await PATCH(req);
	return response;
};

export const createLesson = async lesson => {
	let response;
	if (lesson._id) {
		const req = {
			url: `/lessons/${lesson._id}`,
			data: lesson,
		};
		response = await PUT(req);
	} else {
		const req = {
			url: `/lessons`,
			data: lesson,
		};
		response = await POST(req);
	}
	return response;
};

export const updateLessonTags = async (model, id) => {
	const req = {
		url: `/lessons/${id}`,
		data: model,
	};
	const response = await PATCH(req);
	return response;
};

export const deleteLessonDetails = async lessonId => {
	const req = {
		url: `/lessons/${lessonId}`,
		data: { language: 'en' },
	};

	const response = await DELETE(req);
	return response;
};

export const activateLessonDetails = async (lessonId, status) => {
	const req = {
		url: `/lessons/${lessonId}`,
		data: { active: status, language: 'en' },
	};

	const response = await PATCH(req);
	return response;
};

export const saveLesson = data => ({
	type: SAVE_LESSON,
	data,
});

export const deleteLesson = () => ({
	type: DELETE_LESSON,
});

export const saveFileSize = data => ({
	type: SAVE_FILE_SIZE,
	data,
});

export const saveScreensData = data => ({
	type: SAVE_SCREENS_NUM,
	data,
});

export const listLessonData = async (
	page,
	pageSize,
	sortKey,
	sortOrderAsc,
	keyword,
	searchKey,
	startDate,
	endDate,
	selected
) => {
	const sortOrder = sortOrderAsc ? 'asc' : 'desc';
	let req = {};
	let url = `lessons?limit=${pageSize}&page=${page}&sortKey=${sortKey}&sortOrder=${sortOrder}`;
	if (searchKey && keyword?.trim().length) {
		url += `&searchKey=${searchKey}`;
	}
	if (keyword?.trim().length) {
		let key;
		if (keyword.includes('#') || keyword.includes('&')) {
			key = encodeURIComponent(keyword);
		} else {
			key = keyword
				.trim()
				.toLowerCase()
				.replace(/[^a-z\d#&]/g, '\\$&');
		}
		// let key = keyword
		// 	.toLowerCase()
		// 	.trim()
		// 	.replace(/[^a-z\d#&]/g, '\\$&');
		url += `&keyword=${key?.trim()}`;
	}
	if (selected?.length > 0) {
		url += `&status=${selected?.map(x => x.value).join(',')}`;
	}
	if (startDate && endDate) {
		url += `&publishedFrom=${startDate}&publishedTo=${endDate}`;
	}
	req = {
		url,
	};

	const response = await GET(req);
	if (response.status === 200) {
		return response;
	}
};
export const lessonDetailsData = async (id, dispatch) => {
	const req = {
		url: `lessons/${id}`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		dispatch(getLessonDetailsData(response));
		dispatch(clearLesson({ ...response?.data?.data }));
		return response;
	}
};
export const tagsList = async dispatch => {
	const req = {
		url: `/tags`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		dispatch(getTagsList(response));
		return response;
	}
};
export const publishLesson = async (lessonId, data) => {
	const req = {
		url: `/lessons/${lessonId}`,
		data,
	};

	const response = await PATCH(req);
	return response;
};
export const lessonVesrionHistory = async (id, dispatch) => {
	const req = {
		url: `lessons/${id}/revisions`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		dispatch(getLessonHistory(response));
		return response;
	}
};
export const lessonHistoryDetails = async (id, revisionId, dispatch) => {
	const req = {
		url: `lessons/${id}/revisions/${revisionId}`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		dispatch(getLessonHistoryDetails(response));
		return response;
	}
};
function reducer(state, action) {
	const initialData = getCache(StorageKey.LESSON_DATA);
	switch (action.type) {
		case SAVE_LESSON:
			setCache(StorageKey.LESSON_DATA, { lesson: { ...state?.lesson, ...action.data } });
			return {
				...state,
				lesson: { ...state.lesson, ...action.data },
			};
		case CLEAR_LESSON:
			setCache(StorageKey.LESSON_DATA, { lesson: { ...action.data } });
			return {
				...state,
				lesson: { ...action.data },
			};
		case GET_LESSON_DATA:
			return {
				...state,
				...action.data,
			};
		case GET_LESSON_DETAILS_DATA:
			return {
				...state,
				lessonDetails: action.data.data.data,
			};
		case GET_TAGS_LIST:
			return {
				...state,
				tags: action.data.data.data.tags,
			};
		case DELETE_LESSON:
			remCache(StorageKey.LESSON_DATA);
			return null;
		case GET_LESSON_HISTORY:
			return {
				...state,
				lessonHistory: action.data.data.data.records,
			};
		case GET_LESSON_HISTORY_DETAILS:
			return {
				...state,
				lessonHistoryDetails: action.data,
			};
		case SAVE_FILE_SIZE:
			setCache(StorageKey.LESSON_DATA, action.data);
			return {
				...state,
				fileSize: action.data,
			};
		case SAVE_SCREENS_NUM:
			setCache(StorageKey.LESSON_DATA, action.data);
			return {
				...state,
				ScreensDetails: action.data,
			};

		default:
			return initialData;
	}
}
export default reducer;
