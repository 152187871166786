import { GET, PATCH, DELETE, POST, PUT } from '../../services/http';
import { startDateFormatterApi, endDateFormatterApi } from '../../helpers/utilities';

const FILE = 'FILE://COURSESCHEDULE';

const generateActionType = type => `${FILE}/${type}`;

const GET_GROUPS_LIST = generateActionType('GET_GROUPS_LIST');

export const getSchedule = data => ({
	type: GET_GROUPS_LIST,
	data,
});

export const archiveSchedule = async (id, isActive) => {
	const req = {
		url: `/courseSchedules/${id}`,
		data: { active: !isActive },
	};
	const response = await PATCH(req);
	return response;
};

export const forceCompleteSchedule = async id => {
	const req = {
		url: `/courseSchedules/${id}`,
		data: { complete: true, active: true },
	};
	const response = await PATCH(req);
	return response;
};

export const endTrackMastery = async id => {
	const req = {
		url: `/courseSchedules/endTrack/${id}`,
	};
	const response = await PATCH(req);
	return response;
};

export const deleteSchedule = async id => {
	const req = {
		url: `courseSchedules/${id}`,
	};
	const response = await DELETE(req);
	if (response.status === 204) {
		return response;
	}
};

export const listSchedule = async (
	page,
	pageSize,
	sortKey,
	sortOrderAsc,
	keyword,
	startDate,
	endDate,
	searchKey,
	statusFilterKey,
	statusFilterCol
) => {
	const sortOrder = sortOrderAsc ? 'asc' : 'desc';

	let req = {};
	let url = `courseSchedules?limit=${pageSize}&page=${page}&sortKey=${sortKey}&sortOrder=${sortOrder}`;
	if (searchKey && keyword?.trim().length) {
		url += `&searchKey=${searchKey}`;
	}
	if (keyword?.trim().length) {
		let key;
		if (keyword.includes('#') || keyword.includes('&')) {
			key = encodeURIComponent(keyword);
		} else {
			key = keyword
				.trim()
				.toLowerCase()
				.replace(/[^a-z\d#&]/g, '\\$&');
		}
		url += `&keyword=${key?.trim()}`;
	}
	if (statusFilterCol?.trim().length && statusFilterKey?.length > 0) {
		url += `&${'status'}=${statusFilterKey?.map(x => x.value).join(',')}`;
	}
	if (startDate && endDate) {
		url += `&startDateFrom=${startDateFormatterApi(startDate)}&startDateTo=${endDateFormatterApi(
			endDate
		)}`;
	}

	req = {
		url,
	};
	const response = await GET(req);
	if (response?.status === 200) {
		return response;
	}
};

export const courseList = async () => {
	const req = {
		url: `/courses/?list=dropdown`,
	};
	const response = await GET(req);
	if (response?.status === 200) {
		return response;
	}
};

export const studentList = async () => {
	const req = {
		url: `/users?type=dropdown&role=student`,
	};
	const response = await GET(req);
	if (response?.status === 200) {
		return response;
	}
};

export const batchList = async () => {
	const req = {
		url: `/groups?type=dropdown`,
	};
	const response = await GET(req);
	if (response?.status === 200) {
		return response;
	}
};

export const staffList = async () => {
	const req = {
		url: `/users?type=dropdown&role=instructor`,
	};
	const response = await GET(req);
	if (response?.status === 200) {
		return response;
	}
};

export const LocationList = async () => {
	const req = {
		url: `/locations`,
	};
	const response = await GET(req);
	if (response?.status === 200) {
		return response;
	}
};

export const GetSchedule = async id => {
	const req = {
		url: `/courseSchedules/${id}`,
	};
	const response = await GET(req);
	if (response?.status === 200) {
		return response;
	}
};

export const saveSchedule = async schedule => {
	let response;
	if (schedule.id) {
		const req = {
			url: `/courseSchedules/${schedule.id}`,
			data: schedule,
		};
		response = await PUT(req);
	} else {
		const req = {
			url: `/courseSchedules`,
			data: schedule,
		};
		response = await POST(req);
	}
	return response;
};

export const publishSchedule = async id => {
	const req = {
		url: `/courseSchedules/${id}`,
		data: { publish: true },
	};

	const response = await PATCH(req);

	if (response !== undefined) {
		return response;
	}
};

export const createSessionName = async (courseId, id) => {
	let url = `/courseSchedules/code/${courseId}`;

	if (id) {
		url += `?sessionId=${id}`;
	}

	const req = {
		url,
	};

	const response = await GET(req);

	if (response?.status === 200) {
		return response;
	}
};

function reducer(state, action) {
	switch (action.type) {
		case GET_GROUPS_LIST:
			return {
				...state,
				...action.data,
			};
		default:
			return null;
	}
}

export default reducer;
