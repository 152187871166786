import { StorageKey } from '../../helpers/constants';
import { DELETE, GET, PATCH, POST, PUT } from '../../services/http';
import { setCache } from '../../services/storage';

const FILE = 'FILE://USERS';

const generateActionType = type => `${FILE}/${type}`;

const GET_USERS_LIST = generateActionType('GET_USERS_LIST');
const GET_ROLE_LIST = generateActionType('GET_ROLE_LIST');
const GET_DEPT_LIST = generateActionType('GET_DEPT_LIST');
const GET_COUNTRY_CODE = generateActionType('GET_COUNTRY_CODE');
const SAVE_USER_DATA = generateActionType('SAVE_USER_DATA');

export const getUsers = data => ({
	type: GET_USERS_LIST,
	data,
});
export const getRoleList = data => ({
	type: GET_ROLE_LIST,
	data,
});
export const getDeptList = data => ({
	type: GET_DEPT_LIST,
	data,
});
export const getCountryCode = data => ({
	type: GET_COUNTRY_CODE,
	data,
});

export const saveUserData = data => ({
	type: SAVE_USER_DATA,
	data,
});

export const getUserDetails = async id => {
	const req = {
		url: `users/${id}`,
	};

	const response = await GET(req);
	return response;
};

export const createUser = async userData => {
	let url = userData?.id ? `/users/${userData.id}` : '/users';

	const req = {
		url: url,
		data: userData,
	};

	const response = userData?.id ? await PUT(req) : await POST(req);
	return response;
};
export const deleteImage = async id => {
	let url = `/users/${id}/avatar`;

	const req = {
		url: url,
	};

	const response = await DELETE(req);
	return response;
};

export const listUsers = async (
	page,
	pageSize,
	sortKey,
	sortOrderAsc,
	keyword,
	searchKey,
	deptFilterKey,
	deptFilterCol,
	roleFilterKey,
	roleFilterCol,
	statusFilterKey,
	statusFilterCol,
	listType = 'list'
) => {
	const sortOrder = sortOrderAsc ? 'asc' : 'desc';
	let req = {};
	let url = `users?type=${listType}&limit=${pageSize}&page=${page}&sortKey=${sortKey}&sortOrder=${sortOrder}`;
	if (searchKey && keyword?.trim().length) {
		url += `&searchKey=${searchKey}`;
	}
	if (keyword?.trim().length) {
		let key;
		if (keyword.includes('#') || keyword.includes('&')) {
			key = encodeURIComponent(keyword);
		} else {
			key = keyword
				.trim()
				.toLowerCase()
				.replace(/[^a-z\d#&]/g, '\\$&');
		}
		url += `&keyword=${key?.trim()}`;
	}
	if (deptFilterCol?.trim().length && deptFilterKey?.length > 0) {
		url += `&${'department'}=${deptFilterKey?.map(x => encodeURIComponent(x.value))}`;
	}
	if (roleFilterCol?.trim().length && roleFilterKey?.length > 0) {
		url += `&${'role'}=${roleFilterKey?.map(x => x.value).join(',')}`;
	}
	if (statusFilterCol?.trim().length && statusFilterKey?.length > 0) {
		url += `&${'status'}=${statusFilterKey?.map(x => x.value).join(',')}`;
	}

	req = {
		url,
	};

	const response = await GET(req);
	if (response?.status === 200) {
		return response;
	}
};

export const archiveUser = async (id, isActive) => {
	const req = {
		url: `/users/${id}/status`,
		data: { status: !isActive },
	};
	const response = await PATCH(req);
	return response;
};

export const deleteUser = async id => {
	const req = {
		url: `users/${id}`,
	};
	const response = await DELETE(req);
	if (response?.status === 204) {
		return response;
	}
};

export const roleList = async dispatch => {
	const req = {
		url: `/roles`,
	};

	const response = await GET(req);
	if (response?.status === 200) {
		dispatch(getRoleList(response));
		return response;
	}
};
export const countryCode = async dispatch => {
	const req = {
		url: `/country-codes`,
	};

	const response = await GET(req);
	if (response?.status === 200) {
		dispatch(getCountryCode(response));
		return response;
	}
};

export const deptList = async dispatch => {
	const req = {
		url: `/departments`,
	};

	const response = await GET(req);
	if (response?.status === 200) {
		dispatch(getDeptList(response));
		return response;
	}
};
export const resetPassword = async email => {
	const req = {
		url: `/accounts/forgotPassword`,
		data: { email: email },
	};

	const response = await PATCH(req);
	return response;
};

export const sendInvitation = async userId => {
	const req = {
		url: `/users/send-invitation`,
		data: { userId: userId },
	};

	const response = await POST(req);
	return response;
};

export const addDepartment = async department => {
	const req = {
		url: `departments`,
		data: { department: [department] },
	};

	const response = await POST(req);

	if (response?.status === 201 || response?.status === 406) {
		return response;
	}
};

export const fileUpload = async file => {
	const formData = new FormData();
	formData.append('file', file);
	const req = {
		url: '/users/import',
		data: formData,
	};
	const response = await POST(req);
	return response;
};

function reducer(state, action) {
	switch (action.type) {
		case GET_USERS_LIST:
			return {
				...state,
				...action.data,
			};
		case GET_ROLE_LIST:
			return {
				...state,
				roles: action.data.data.data,
			};
		case GET_DEPT_LIST:
			setCache(StorageKey.DEPTS, action.data.data.data);
			return {
				...state,
				dept: action.data.data.data,
			};
		case SAVE_USER_DATA:
			return {
				...state,
				userData: action.data,
			};
		case GET_COUNTRY_CODE:
			return {
				...state,
				countryCode: action.data,
			};
		default:
			return null;
	}
}

export default reducer;
